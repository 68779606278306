<template>
  <div class="ma-2">
    <ERow no-gutters class="item-details">
      <ECol v-for="(item, index) in details" :key="index">
        <v-list-item two-line class="px-0 mr-2">
          <v-list-item-content class="py-0 pt-1">
            <v-list-item-title class="caption font-weight-bold">{{
              item.label
            }}</v-list-item-title>
            <v-list-item-subtitle class="score-bar">
              {{ item.value }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </ECol>
    </ERow>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue"
import { Project } from "@evercam/shared/types/project"
export default Vue.extend({
  props: {
    projectData: {
      type: Object as PropType<Project>,
      required: true,
    },
  },
  computed: {
    details() {
      return [
        {
          label: "ID",
          value: this.projectData.id,
        },
        {
          label: "Status",
          value: this.projectData.status ? this.projectData.status : "n/a",
        },
        {
          label: "Start Date",
          value: this.formatDate(this.projectData.insertedAt, "DD MMM YYYY"),
        },
      ]
    },
  },
})
</script>

<style lang="scss">
.item-details {
  .v-list-item {
    min-height: 0px !important;
  }
  .score-bar {
    position: relative;
    height: 15px;
  }
}
</style>
