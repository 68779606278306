<template>
  <div>
    <ERow v-resize-observer="onResize" class="mt-4" justify="start" no-gutters>
      <ECol cols="6" class="py-0">
        <v-data-table
          :headers="projectOwnerHeaders"
          :items="loading ? [] : [projectOwner]"
          :loading="loading"
          loading-text="Loading..."
          class="custom-data-table"
          dense
          hide-default-footer
        >
          <template #top>
            <v-container ref="headerContainer" fluid class="py-0">
              <ERow class="py-0" no-gutters>
                <ECol class="shrink pl-0 py-0 text-h6"> Owner </ECol>
              </ERow>
            </v-container>
          </template>
          <template v-if="!loading" #[`item.fullname`]="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click="openUserDialog(item.email)"
            >
              {{ item.fullname }}
            </span>
          </template>
        </v-data-table>
      </ECol>
    </ERow>
    <ERow v-resize-observer="onResize" class="mt-4" justify="center" no-gutters>
      <ECol ref="tableContainer" cols="12" class="py-0">
        <v-data-table
          ref="usersTable"
          v-model="selected"
          :headers="allHeaders"
          :items="items"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="custom-data-table"
          :height="tableHeight"
          dense
          must-sort
          :loading="loading"
          loading-text="Loading..."
          calculate-widths
          :mobile-breakpoint="0"
          :options.sync="options"
          :server-items-length="total"
          item-key="id"
          fixed-header
          :footer-props="{
            'items-per-page-options': [-1, 50, 100, 500],
          }"
          :hide-default-footer="items.length <= 50"
        >
          <template #top>
            <v-container ref="headerContainer" fluid class="py-0">
              <ERow class="py-0" no-gutters>
                <ECol class="shrink pl-0 py-0 text-h6"> Users </ECol>
                <v-spacer />
                <ECol class="grow mb-1 py-0 text-right">
                  <CopyTableToClipboard
                    :headers="allHeaders"
                    :items="filteredItems"
                  />
                </ECol>
              </ERow>
            </v-container>
          </template>
          <template v-if="!loading" #body="props">
            <tbody>
              <tr v-for="(item, i) in props.items" :key="i">
                <td v-for="header in headers" :key="header.value">
                  <span
                    v-if="header.value === 'lastSeenAt'"
                    class="d-flex justify-center"
                  >
                    <v-tooltip top>
                      <template #activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ relativeDate(item[header.value]) }}
                        </span>
                      </template>
                      <span>
                        {{ formatDate(item[header.value]) }}
                      </span>
                    </v-tooltip>
                  </span>
                  <span
                    v-else-if="
                      header.value === 'active' ||
                      header.value === 'activeOneMonth'
                    "
                    class="d-flex justify-center"
                  >
                    <v-icon :color="item[header.value] ? 'green' : 'red'">
                      {{ item[header.value] ? "fa-check" : "fa-times" }}
                    </v-icon>
                  </span>
                  <span v-else-if="header.value === 'fullname'">
                    <span
                      class="cursor-pointer primary--text"
                      @click="openUserDialog(item.email)"
                    >
                      {{ item.fullname }}
                    </span>
                  </span>
                  <span v-else> {{ item[header.value] }} </span>
                </td>
                <td
                  v-for="header in camerasShareStatusHeaders"
                  :key="header.value"
                  :class="{
                    'red lighten-4': !rightsLabels[item[header.value]],
                  }"
                >
                  <span v-if="!rightsLabels[item[header.value]]">
                    No Access
                    <v-btn
                      color="primary"
                      text
                      x-small
                      @click="giveAccess(item.email, header)"
                    >
                      Give it
                    </v-btn>
                  </span>
                  <span v-else>
                    {{ rightsLabels[item[header.value]] }}
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </ECol>
    </ERow>
  </div>
</template>

<script lang="ts">
import projectsUsersTableHeader from "@/components/projects/projectsUsersTableHeaders"
import ResizeTable from "@/mixins/resizeTable"
import CopyTableToClipboard from "@evercam/shared/components/CopyTableToClipboard"
import Vue from "vue"
import { ShareCreateRequestPayload } from "@evercam/shared/types/shares"
import { ProjectUser } from "@evercam/shared/types/user"
import { decamelize } from "humps"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useUserStore } from "@/stores/users"

export default Vue.extend({
  components: {
    CopyTableToClipboard,
  },
  mixins: [ResizeTable],
  props: {
    projectId: {
      type: [String, undefined],
      default: undefined,
    },
    companyId: {
      type: [String, undefined],
      default: undefined,
    },
  },
  data() {
    return {
      tableHeight: null,
      selected: [],
      items: [],
      headers: [],
      camerasShareStatusHeaders: [],
      options: {},
      total: 0,
      loading: true,
      sortBy: "fullname",
      sortDesc: true,
      rightsLabels: {
        owner: "Owner",
        read_only: "Read Only",
        read_only_share: "Read + Share ",
        full_rights: "Full Rights",
      },
      projectOwnerHeaders: [],
      projectOwner: {},
    }
  },
  computed: {
    allHeaders() {
      return this.headers.concat(this.camerasShareStatusHeaders)
    },
    filteredItems() {
      let filteredItems = this.$refs.usersTable?.internalCurrentItems

      return filteredItems ? filteredItems : this.items
    },
  },
  watch: {
    projectId() {
      this.fetchUsersAndCameras()
    },
  },
  async mounted() {
    this.headers = projectsUsersTableHeader(this)
    this.projectOwnerHeaders = projectsUsersTableHeader(this).slice(0, 3)
    await this.fetchUsersAndCameras()
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    async fetchUsersAndCameras() {
      this.items = []
      this.camerasShareStatusHeaders = []
      if (!this.projectId) {
        return
      }
      try {
        this.loading = true
        const response = await AdminApi.pa.getProjectUsers(this.projectId)
        this.loading = false
        this.total = response.total
        let items = response.users,
          item,
          eventsCount,
          lastSeenAt,
          active,
          activeOneMonth
        this.projectOwner = { ...response.owner }
        response.cameras.forEach((camera) => {
          this.camerasShareStatusHeaders.push({
            value: camera.exid,
            text: `${camera.name}`,
            visible: true,
            width: 120,
            align: "center",
            toStringFn: (item, key) =>
              this.rightsLabels[item[key]] || "No Access",
          })
        })

        Object.entries(items).forEach(
          ([email, users]: [string, ProjectUser[]]) => {
            item = {
              fullname: users[0].fullname,
              persona: users[0].persona,
              email: decamelize(email, { separator: "-" }),
              id: users[0].id,
            }
            eventsCount = 0
            lastSeenAt = "-"
            active = false
            activeOneMonth = false

            users.forEach((user) => {
              if (this.$moment(user.lastSeenAt).isValid()) {
                if (
                  !this.$moment(lastSeenAt).isValid() ||
                  this.$moment(user.lastSeenAt).isAfter(
                    this.$moment(lastSeenAt)
                  )
                ) {
                  lastSeenAt = user.lastSeenAt
                }
              }

              if (user.active) {
                active = true
              }

              if (user.activeOneMonth) {
                activeOneMonth = true
              }

              eventsCount += parseInt(user.eventsCount?.toString() || "0")
              item = {
                ...item,
                [user.cameraExid]: user.cameraRights,
                eventsCount,
                lastSeenAt,
                active,
                activeOneMonth,
              }
            })
            this.items.push(item)
          }
        )
      } catch (error) {
        this.$notifications.error({ text: "Could not load Cameras", error })
      }
    },
    async giveAccess(email, camera) {
      try {
        let payload: ShareCreateRequestPayload = {
          rights: "list,snapshot",
          email,
        }
        await AdminApi.shares.createShares(camera.value, payload)
        await this.fetchUsersAndCameras()
        this.$notifications.success(
          `${email} user had successfully access to ${camera.text} camera`
        )
      } catch (error) {
        this.$notifications.error({
          text: `Could not give ${email} user access to ${camera.text} camera`,
          error,
        })
      }
    },
  },
})
</script>
