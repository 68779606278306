var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('ERow',{directives:[{name:"resize-observer",rawName:"v-resize-observer",value:(_vm.onResize),expression:"onResize"}],staticClass:"mt-4",attrs:{"justify":"start","no-gutters":""}},[_c('ECol',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"custom-data-table",attrs:{"headers":_vm.projectOwnerHeaders,"items":_vm.loading ? [] : [_vm.projectOwner],"loading":_vm.loading,"loading-text":"Loading...","dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{ref:"headerContainer",staticClass:"py-0",attrs:{"fluid":""}},[_c('ERow',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('ECol',{staticClass:"shrink pl-0 py-0 text-h6"},[_vm._v(" Owner ")])],1)],1)]},proxy:true},(!_vm.loading)?{key:`item.fullname`,fn:function({ item }){return [_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){return _vm.openUserDialog(item.email)}}},[_vm._v("\n            "+_vm._s(item.fullname)+"\n          ")])]}}:null],null,true)})],1)],1),_vm._v(" "),_c('ERow',{directives:[{name:"resize-observer",rawName:"v-resize-observer",value:(_vm.onResize),expression:"onResize"}],staticClass:"mt-4",attrs:{"justify":"center","no-gutters":""}},[_c('ECol',{ref:"tableContainer",staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{ref:"usersTable",staticClass:"custom-data-table",attrs:{"headers":_vm.allHeaders,"items":_vm.items,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"height":_vm.tableHeight,"dense":"","must-sort":"","loading":_vm.loading,"loading-text":"Loading...","calculate-widths":"","mobile-breakpoint":0,"options":_vm.options,"server-items-length":_vm.total,"item-key":"id","fixed-header":"","footer-props":{
          'items-per-page-options': [-1, 50, 100, 500],
        },"hide-default-footer":_vm.items.length <= 50},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{ref:"headerContainer",staticClass:"py-0",attrs:{"fluid":""}},[_c('ERow',{staticClass:"py-0",attrs:{"no-gutters":""}},[_c('ECol',{staticClass:"shrink pl-0 py-0 text-h6"},[_vm._v(" Users ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('ECol',{staticClass:"grow mb-1 py-0 text-right"},[_c('CopyTableToClipboard',{attrs:{"headers":_vm.allHeaders,"items":_vm.filteredItems}})],1)],1)],1)]},proxy:true},(!_vm.loading)?{key:"body",fn:function(props){return [_c('tbody',_vm._l((props.items),function(item,i){return _c('tr',{key:i},[_vm._l((_vm.headers),function(header){return _c('td',{key:header.value},[(header.value === 'lastSeenAt')?_c('span',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v("\n                        "+_vm._s(_vm.relativeDate(item[header.value]))+"\n                      ")])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n                      "+_vm._s(_vm.formatDate(item[header.value]))+"\n                    ")])])],1):(
                    header.value === 'active' ||
                    header.value === 'activeOneMonth'
                  )?_c('span',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":item[header.value] ? 'green' : 'red'}},[_vm._v("\n                    "+_vm._s(item[header.value] ? "fa-check" : "fa-times")+"\n                  ")])],1):(header.value === 'fullname')?_c('span',[_c('span',{staticClass:"cursor-pointer primary--text",on:{"click":function($event){return _vm.openUserDialog(item.email)}}},[_vm._v("\n                    "+_vm._s(item.fullname)+"\n                  ")])]):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])])}),_vm._v(" "),_vm._l((_vm.camerasShareStatusHeaders),function(header){return _c('td',{key:header.value,class:{
                  'red lighten-4': !_vm.rightsLabels[item[header.value]],
                }},[(!_vm.rightsLabels[item[header.value]])?_c('span',[_vm._v("\n                  No Access\n                  "),_c('v-btn',{attrs:{"color":"primary","text":"","x-small":""},on:{"click":function($event){return _vm.giveAccess(item.email, header)}}},[_vm._v("\n                    Give it\n                  ")])],1):_c('span',[_vm._v("\n                  "+_vm._s(_vm.rightsLabels[item[header.value]])+"\n                ")])])})],2)}),0)]}}:null],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }